import 'antd/dist/reset.css';
import { DatePicker } from 'antd';
import { format } from 'date-fns';
import dayjs from 'dayjs';
interface DateRangeProps {
    dates: [string | null, string | null];
    setDates: (dates: [string | null, string | null]) => void;
    setRange: (range: [string | null, string | null]) => void;
}
function DateRange({ dates, setDates, setRange }: DateRangeProps) {
    const { RangePicker } = DatePicker;

    const handleDateChange = (values: any) => {
        if (values) {
            const formattedDates = values.map((date: any) => date ? format(new Date(date), 'yyyy-MM-dd') : null);
            setDates(formattedDates);
            setRange(formattedDates);
        } else {
            setDates([null, null]);
            setRange([null, null]);
        }
    };

    return (
        <div style={{ marginRight: "4px", marginLeft: "4px", marginTop: "20px", marginBottom: "20px" }}>
            <RangePicker
                value={[
                    dates[0] ? dayjs(new Date(dates[0])) : null,
                    dates[1] ? dayjs(new Date(dates[1])) : null,
                ]}
                style={{ width: '100%' }}
                onChange={handleDateChange}
            />
        </div>
    );
}

export default DateRange;