import { format } from 'date-fns';
export const sampleData = [
    {
        "id": 1,
        "name": "Nutrition Attachment",
        "category": "Health",
        "subcategory": "Nutrition",
        "createdAt": "2016-03-24T05:28:03.099+05:30",
        "updatedAt": "2016-03-24T05:28:03.099+05:30",
        "price": 24.99,
        "sale_price": 21.95
    },
    {
        "id": 2,
        "name": "Mens Gadget",
        "category": "Clothing",
        "subcategory": "Mens",
        "createdAt": "2024-02-28T06:00:38.635+05:30",
        "updatedAt": "2024-02-28T06:00:38.635+05:30",
        "price": 14.95,
        "sale_price": 12.99
    },
    {
        "id": 3,
        "name": "Outdoors Gear",
        "category": "Activity",
        "subcategory": "Outdoors",
        "createdAt": "2016-06-03T14:12:33.623+05:30",
        "updatedAt": "2016-06-03T14:12:33.623+05:30",
        "price": 86.9
    },
    {
        "id": 4,
        "name": "Camping Paraphernalia",
        "category": "Activity",
        "subcategory": "Camping",
        "createdAt": "2018-09-06T21:54:00.264+05:30",
        "updatedAt": "2018-09-06T21:54:00.264+05:30",
        "price": 113.95,
        "sale_price": 67.95
    },
    {
        "id": 5,
        "name": "Cleaning Component",
        "category": "Home",
        "subcategory": "Cleaning",
        "createdAt": "2015-11-28T21:36:56.016+05:30",
        "updatedAt": "2015-11-28T21:36:56.016+05:30",
        "price": 158.99,
        "sale_price": 126
    },
    {
        "id": 6,
        "name": "Cleaning Implement",
        "category": "Home",
        "subcategory": "Cleaning",
        "createdAt": "2017-02-12T06:26:55.781+05:30",
        "updatedAt": "2017-02-12T06:26:55.781+05:30",
        "price": 73,
        "sale_price": null
    },
    {
        "id": 7,
        "name": "Outdoors Apparatus",
        "category": "Activity",
        "subcategory": "Outdoors",
        "createdAt": "2018-09-13T05:17:38.176+05:30",
        "updatedAt": "2018-09-13T05:17:38.176+05:30",
        "price": 188.95,
        "sale_price": 169.99
    },
    {
        "id": 8,
        "name": "Aquarium Gadget",
        "category": "Pets",
        "subcategory": "Aquarium",
        "createdAt": "2017-03-27T10:14:52.483+05:30",
        "updatedAt": "2017-03-27T10:14:52.483+05:30",
        "price": 123.9
    },
    {
        "id": 9,
        "name": "Cat Gear",
        "category": "Pets",
        "subcategory": "Cat",
        "createdAt": "2012-04-26T16:41:24.239+05:30",
        "updatedAt": "2012-04-26T16:41:24.239+05:30",
        "price": 199,
        "sale_price": 119
    },
    {
        "id": 10,
        "name": "Tire Rig",
        "category": "Automotive",
        "subcategory": "Tire",
        "createdAt": "2019-01-13T19:58:52.563+05:30",
        "updatedAt": "2019-01-13T19:58:52.563+05:30",
        "price": 168.99,
        "sale_price": 117
    },
    {
        "id": 11,
        "name": "Cat Equipment",
        "category": "Pets",
        "subcategory": "Cat",
        "createdAt": "2010-10-08T01:34:14.617+05:30",
        "updatedAt": "2010-10-08T01:34:14.617+05:30",
        "price": 76,
        "sale_price": 45.99
    },
    {
        "id": 12,
        "name": "Jewelry Machine",
        "category": "Clothing",
        "subcategory": "Jewelry",
        "createdAt": "2019-04-09T23:24:28.136+05:30",
        "updatedAt": "2019-04-09T23:24:28.136+05:30",
        "price": 193.95,
        "sale_price": 154.99
    },
    {
        "id": 13,
        "name": "Movie Apparatus",
        "category": "Entertainment",
        "subcategory": "Movie",
        "createdAt": "2017-10-11T21:41:15.748+05:30",
        "updatedAt": "2017-10-11T21:41:15.748+05:30",
        "price": 62.95,
        "sale_price": 43.95
    },
    {
        "id": 14,
        "name": "Tire Tool",
        "category": "Automotive",
        "subcategory": "Tire",
        "createdAt": "2022-12-03T01:24:57.034+05:30",
        "updatedAt": "2022-12-03T01:24:57.034+05:30",
        "price": 183.95,
        "sale_price": 146.99
    },
    {
        "id": 15,
        "name": "Tire Item",
        "category": "Automotive",
        "subcategory": "Tire",
        "createdAt": "2023-05-17T10:35:33.954+05:30",
        "updatedAt": "2023-05-17T10:35:33.954+05:30",
        "price": 128.95,
        "sale_price": 102.95
    },
    {
        "id": 16,
        "name": "Furniture Device",
        "category": "Home",
        "subcategory": "Furniture",
        "createdAt": "2022-08-03T09:13:10.578+05:30",
        "updatedAt": "2022-08-03T09:13:10.578+05:30",
        "price": 15
    },
    {
        "id": 17,
        "name": "Camping Device",
        "category": "Activity",
        "subcategory": "Camping",
        "createdAt": "2010-10-30T20:54:13.941+05:30",
        "updatedAt": "2010-10-30T20:54:13.941+05:30",
        "price": 193,
        "sale_price": null
    },
    {
        "id": 18,
        "name": "Baby Attachment",
        "category": "Clothing",
        "subcategory": "Baby",
        "createdAt": "2014-08-28T10:00:48.808+05:30",
        "updatedAt": "2014-08-28T10:00:48.808+05:30",
        "price": 86.95,
        "sale_price": 60.99
    },
    {
        "id": 19,
        "name": "First Aid Device",
        "category": "Health",
        "subcategory": "First Aid",
        "createdAt": "2017-12-30T05:40:14.736+05:30",
        "updatedAt": "2017-12-30T05:40:14.736+05:30",
        "price": 57.99,
        "sale_price": 39.99
    },
    {
        "id": 20,
        "name": "Home Improvement Rig",
        "category": "Home",
        "subcategory": "Home Improvement",
        "createdAt": "2014-08-27T04:30:19.633+05:30",
        "updatedAt": "2014-08-27T04:30:19.633+05:30",
        "price": 145.9
    },
    {
        "id": 21,
        "name": "Camping Gear",
        "category": "Activity",
        "subcategory": "Camping",
        "createdAt": "2022-03-25T21:11:54.423+05:30",
        "updatedAt": "2022-03-25T21:11:54.423+05:30",
        "price": 81.95,
        "sale_price": 48
    },
    {
        "id": 22,
        "name": "Bath Mechanism",
        "category": "Beauty",
        "subcategory": "Bath",
        "createdAt": "2016-07-21T03:18:03.513+05:30",
        "updatedAt": "2016-07-21T03:18:03.513+05:30",
        "price": 185,
        "sale_price": 148
    },
    {
        "id": 23,
        "name": "Baby Thingamajig",
        "category": "Clothing",
        "subcategory": "Baby",
        "createdAt": "2016-05-15T13:38:37.719+05:30",
        "updatedAt": "2016-05-15T13:38:37.719+05:30",
        "price": 108.99,
        "sale_price": 86.99
    },
    {
        "id": 24,
        "name": "Kids Item",
        "category": "Clothing",
        "subcategory": "Kids",
        "createdAt": "2011-03-09T16:01:48.314+05:30",
        "updatedAt": "2011-03-09T16:01:48.314+05:30",
        "price": 186,
        "sale_price": null
    },
    {
        "id": 25,
        "name": "Mens Instrument",
        "category": "Clothing",
        "subcategory": "Mens",
        "createdAt": "2013-04-30T03:08:40.797+05:30",
        "updatedAt": "2013-04-30T03:08:40.797+05:30",
        "price": 105,
        "sale_price": 73.99
    },
    {
        "id": 26,
        "name": "Sports Whatchamacallit",
        "category": "Activity",
        "subcategory": "Sports",
        "createdAt": "2016-06-11T03:38:26.225+05:30",
        "updatedAt": "2016-06-11T03:38:26.225+05:30",
        "price": 23.95,
        "sale_price": 13.95
    },
    {
        "id": 27,
        "name": "First Aid Tool",
        "category": "Health",
        "subcategory": "First Aid",
        "createdAt": "2018-10-06T13:09:10.021+05:30",
        "updatedAt": "2018-10-06T13:09:10.021+05:30",
        "price": 197.95,
        "sale_price": 157.99
    },
    {
        "id": 28,
        "name": "Party Contraption",
        "category": "Entertainment",
        "subcategory": "Party",
        "createdAt": "2023-12-08T13:50:42.356+05:30",
        "updatedAt": "2023-12-08T13:50:42.356+05:30",
        "price": 146.99,
        "sale_price": 116.95
    },
    {
        "id": 29,
        "name": "Furniture Instrument",
        "category": "Home",
        "subcategory": "Furniture",
        "createdAt": "2019-11-01T02:41:28.313+05:30",
        "updatedAt": "2019-11-01T02:41:28.313+05:30",
        "price": 168.99,
        "sale_price": 100
    },
    {
        "id": 30,
        "name": "Computer Instrument",
        "category": "Electronics",
        "subcategory": "Computer",
        "createdAt": "2022-07-24T04:48:25.285+05:30",
        "updatedAt": "2022-07-24T04:48:25.285+05:30",
        "price": 42.95,
        "sale_price": null
    },
    {
        "id": 31,
        "name": "Sports Widget",
        "category": "Activity",
        "subcategory": "Sports",
        "createdAt": "2014-01-10T02:37:42.832+05:30",
        "updatedAt": "2014-01-10T02:37:42.832+05:30",
        "price": 77.9
    },
    {
        "id": 32,
        "name": "Dog Supplies",
        "category": "Pets",
        "subcategory": "Dog",
        "createdAt": "2023-09-14T16:36:18.492+05:30",
        "updatedAt": "2023-09-14T16:36:18.492+05:30",
        "price": 87.99,
        "sale_price": 52.95
    },
    {
        "id": 33,
        "name": "Cat Widget",
        "category": "Pets",
        "subcategory": "Cat",
        "createdAt": "2012-09-17T16:25:18.711+05:30",
        "updatedAt": "2012-09-17T16:25:18.711+05:30",
        "price": 108.95,
        "sale_price": 86.95
    },
    {
        "id": 34,
        "name": "Technology Doodad",
        "category": "Electronics",
        "subcategory": "Technology",
        "createdAt": "2018-09-30T15:38:34.749+05:30",
        "updatedAt": "2018-09-30T15:38:34.749+05:30",
        "price": 21,
        "sale_price": 14
    },
    {
        "id": 35,
        "name": "Outdoors Equipment",
        "category": "Activity",
        "subcategory": "Outdoors",
        "createdAt": "2019-05-14T14:46:57.805+05:30",
        "updatedAt": "2019-05-14T14:46:57.805+05:30",
        "price": 183.99,
        "sale_price": 164.99
    },
    {
        "id": 36,
        "name": "Book Component",
        "category": "Entertainment",
        "subcategory": "Book",
        "createdAt": "2015-09-10T23:45:03.538+05:30",
        "updatedAt": "2015-09-10T23:45:03.538+05:30",
        "price": 156,
        "sale_price": 124.95
    },
    {
        "id": 37,
        "name": "Toy Gizmo",
        "category": "Entertainment",
        "subcategory": "Toy",
        "createdAt": "2022-10-27T17:19:56.582+05:30",
        "updatedAt": "2022-10-27T17:19:56.582+05:30",
        "price": 32.95,
        "sale_price": 25
    },
    {
        "id": 38,
        "name": "Engine Paraphernalia",
        "category": "Automotive",
        "subcategory": "Engine",
        "createdAt": "2019-01-22T00:20:34.036+05:30",
        "updatedAt": "2019-01-22T00:20:34.036+05:30",
        "price": 60.95,
        "sale_price": 36.95
    },
    {
        "id": 39,
        "name": "Tire Apparatus",
        "category": "Automotive",
        "subcategory": "Tire",
        "createdAt": "2023-10-26T10:18:21.624+05:30",
        "updatedAt": "2023-10-26T10:18:21.624+05:30",
        "price": 144.9
    },
    {
        "id": 40,
        "name": "Sports Gizmo",
        "category": "Activity",
        "subcategory": "Sports",
        "createdAt": "2021-11-06T22:23:44.333+05:30",
        "updatedAt": "2021-11-06T22:23:44.333+05:30",
        "price": 25.95,
        "sale_price": 20.95
    },
    {
        "id": 41,
        "name": "Cleaning Tool",
        "category": "Home",
        "subcategory": "Cleaning",
        "createdAt": "2017-12-27T12:02:16.386+05:30",
        "updatedAt": "2017-12-27T12:02:16.386+05:30",
        "price": 50,
        "sale_price": 45.99
    },
    {
        "id": 42,
        "name": "Toy Widget",
        "category": "Entertainment",
        "subcategory": "Toy",
        "createdAt": "2013-08-06T07:40:25.643+05:30",
        "updatedAt": "2013-08-06T07:40:25.643+05:30",
        "price": 46.99,
        "sale_price": 32.95
    },
    {
        "id": 43,
        "name": "Sports Tool",
        "category": "Activity",
        "subcategory": "Sports",
        "createdAt": "2017-01-26T02:29:53.945+05:30",
        "updatedAt": "2017-01-26T02:29:53.945+05:30",
        "price": 37.95,
        "sale_price": 33
    },
    {
        "id": 44,
        "name": "Movie Item",
        "category": "Entertainment",
        "subcategory": "Movie",
        "createdAt": "2010-02-05T04:09:35.043+05:30",
        "updatedAt": "2010-02-05T04:09:35.043+05:30",
        "price": 37.95,
        "sale_price": 25
    },
    {
        "id": 45,
        "name": "Music Device",
        "category": "Entertainment",
        "subcategory": "Music",
        "createdAt": "2016-04-08T20:38:55.062+05:30",
        "updatedAt": "2016-04-08T20:38:55.062+05:30",
        "price": 43,
        "sale_price": 34.95
    },
    {
        "id": 46,
        "name": "Dining Mechanism",
        "category": "Home",
        "subcategory": "Dining",
        "createdAt": "2015-07-25T14:20:06.459+05:30",
        "updatedAt": "2015-07-25T14:20:06.459+05:30",
        "price": 58.95,
        "sale_price": 40
    },
    {
        "id": 47,
        "name": "Camping Item",
        "category": "Activity",
        "subcategory": "Camping",
        "createdAt": "2015-04-09T15:00:56.898+05:30",
        "updatedAt": "2015-04-09T15:00:56.898+05:30",
        "price": 119.9
    },
    {
        "id": 48,
        "name": "Technology Widget",
        "category": "Electronics",
        "subcategory": "Technology",
        "createdAt": "2011-11-17T03:51:00.867+05:30",
        "updatedAt": "2011-11-17T03:51:00.867+05:30",
        "price": 170,
        "sale_price": 153.95
    },
    {
        "id": 49,
        "name": "Skin Care Implement",
        "category": "Beauty",
        "subcategory": "Skin Care",
        "createdAt": "2011-11-28T09:08:59.920+05:30",
        "updatedAt": "2011-11-28T09:08:59.920+05:30",
        "price": 42.95,
        "sale_price": 29.99
    },
    {
        "id": 50,
        "name": "Camera Whatchamacallit",
        "category": "Electronics",
        "subcategory": "Camera",
        "createdAt": "2018-05-21T02:44:58.210+05:30",
        "updatedAt": "2018-05-21T02:44:58.210+05:30",
        "price": 140.99,
        "sale_price": 112
    },
    {
        "id": 51,
        "name": "Swimming Tool",
        "category": "Activity",
        "subcategory": "Swimming",
        "createdAt": "2014-07-31T11:08:23.960+05:30",
        "updatedAt": "2014-07-31T11:08:23.960+05:30",
        "price": 177.9
    },
    {
        "id": 52,
        "name": "Kitchen Tool",
        "category": "Home",
        "subcategory": "Kitchen",
        "createdAt": "2021-08-07T13:09:16.288+05:30",
        "updatedAt": "2021-08-07T13:09:16.288+05:30",
        "price": 27.99,
        "sale_price": 24
    },
    {
        "id": 53,
        "name": "Baby Component",
        "category": "Clothing",
        "subcategory": "Baby",
        "createdAt": "2020-06-24T22:12:06.686+05:30",
        "updatedAt": "2020-06-24T22:12:06.686+05:30",
        "price": 160,
        "sale_price": 128
    },
    {
        "id": 54,
        "name": "First Aid Gear",
        "category": "Health",
        "subcategory": "First Aid",
        "createdAt": "2017-06-07T12:16:43.701+05:30",
        "updatedAt": "2017-06-07T12:16:43.701+05:30",
        "price": 28.99,
        "sale_price": null
    },
    {
        "id": 55,
        "name": "First Aid Rig",
        "category": "Health",
        "subcategory": "First Aid",
        "createdAt": "2019-12-22T22:58:55.394+05:30",
        "updatedAt": "2019-12-22T22:58:55.394+05:30",
        "price": 135.95,
        "sale_price": 121
    },
    {
        "id": 56,
        "name": "Game Implement",
        "category": "Entertainment",
        "subcategory": "Game",
        "createdAt": "2016-05-31T03:21:00.228+05:30",
        "updatedAt": "2016-05-31T03:21:00.228+05:30",
        "price": 49.9
    },
    {
        "id": 57,
        "name": "Phone Kit",
        "category": "Electronics",
        "subcategory": "Phone",
        "createdAt": "2015-06-03T15:46:35.075+05:30",
        "updatedAt": "2015-06-03T15:46:35.075+05:30",
        "price": 30,
        "sale_price": 24
    },
    {
        "id": 58,
        "name": "Cleaning Device",
        "category": "Home",
        "subcategory": "Cleaning",
        "createdAt": "2014-11-08T21:34:33.852+05:30",
        "updatedAt": "2014-11-08T21:34:33.852+05:30",
        "price": 103.99,
        "sale_price": 82.99
    },
    {
        "id": 59,
        "name": "Baby Supplies",
        "category": "Clothing",
        "subcategory": "Baby",
        "createdAt": "2020-09-20T22:40:44.681+05:30",
        "updatedAt": "2020-09-20T22:40:44.681+05:30",
        "price": 171.95,
        "sale_price": 102.95
    },
    {
        "id": 60,
        "name": "Home Improvement Machine",
        "category": "Home",
        "subcategory": "Home Improvement",
        "createdAt": "2015-06-08T14:24:52.206+05:30",
        "updatedAt": "2015-06-08T14:24:52.206+05:30",
        "price": 72,
        "sale_price": 43
    },
    {
        "id": 61,
        "name": "Medicine Instrument",
        "category": "Health",
        "subcategory": "Medicine",
        "createdAt": "2020-08-05T07:43:34.352+05:30",
        "updatedAt": "2020-08-05T07:43:34.352+05:30",
        "price": 13,
        "sale_price": 10.95
    },
    {
        "id": 62,
        "name": "First Aid Attachment",
        "category": "Health",
        "subcategory": "First Aid",
        "createdAt": "2018-08-13T18:26:10.284+05:30",
        "updatedAt": "2018-08-13T18:26:10.284+05:30",
        "price": 165.95,
        "sale_price": 115.99
    },
    {
        "id": 63,
        "name": "Sports Gadget",
        "category": "Activity",
        "subcategory": "Sports",
        "createdAt": "2021-01-21T06:20:36.456+05:30",
        "updatedAt": "2021-01-21T06:20:36.456+05:30",
        "price": 42.95,
        "sale_price": 37
    },
    {
        "id": 64,
        "name": "Game Gizmo",
        "category": "Entertainment",
        "subcategory": "Game",
        "createdAt": "2011-02-06T06:21:17.816+05:30",
        "updatedAt": "2011-02-06T06:21:17.816+05:30",
        "price": 13
    },
    {
        "id": 65,
        "name": "Technology Implement",
        "category": "Electronics",
        "subcategory": "Technology",
        "createdAt": "2010-02-12T17:44:55.480+05:30",
        "updatedAt": "2010-02-12T17:44:55.480+05:30",
        "price": 34.95,
        "sale_price": 27.99
    },
    {
        "id": 66,
        "name": "Outdoors Kit",
        "category": "Activity",
        "subcategory": "Outdoors",
        "createdAt": "2018-05-09T05:03:17.075+05:30",
        "updatedAt": "2018-05-09T05:03:17.075+05:30",
        "price": 37.99,
        "sale_price": 33.95
    },
    {
        "id": 67,
        "name": "Car Whatchamacallit",
        "category": "Automotive",
        "subcategory": "Car",
        "createdAt": "2013-10-08T07:11:07.467+05:30",
        "updatedAt": "2013-10-08T07:11:07.467+05:30",
        "price": 52.95,
        "sale_price": 46.99
    },
    {
        "id": 68,
        "name": "Movie Supplies",
        "category": "Entertainment",
        "subcategory": "Movie",
        "createdAt": "2022-03-15T06:39:10.901+05:30",
        "updatedAt": "2022-03-15T06:39:10.901+05:30",
        "price": 196.95,
        "sale_price": 137.95
    },
    {
        "id": 69,
        "name": "Game Thingamajig",
        "category": "Entertainment",
        "subcategory": "Game",
        "createdAt": "2023-07-10T06:41:10.441+05:30",
        "updatedAt": "2023-07-10T06:41:10.441+05:30",
        "price": 184.95,
        "sale_price": 110.95
    },
    {
        "id": 70,
        "name": "Home Improvement Doodad",
        "category": "Home",
        "subcategory": "Home Improvement",
        "createdAt": "2023-03-16T13:26:47.201+05:30",
        "updatedAt": "2023-03-16T13:26:47.201+05:30",
        "price": 15
    },
    {
        "id": 71,
        "name": "Vitamin Kit",
        "category": "Health",
        "subcategory": "Vitamin",
        "createdAt": "2018-03-23T13:27:38.488+05:30",
        "updatedAt": "2018-03-23T13:27:38.488+05:30",
        "price": 52.99,
        "sale_price": 31
    },
    {
        "id": 72,
        "name": "Game Instrument",
        "category": "Entertainment",
        "subcategory": "Game",
        "createdAt": "2017-02-16T04:50:56.318+05:30",
        "updatedAt": "2017-02-16T04:50:56.318+05:30",
        "price": 33.95,
        "sale_price": 26.95
    },
    {
        "id": 73,
        "name": "Bath Component",
        "category": "Beauty",
        "subcategory": "Bath",
        "createdAt": "2018-11-29T19:34:55.102+05:30",
        "updatedAt": "2018-11-29T19:34:55.102+05:30",
        "price": 57.99,
        "sale_price": 51.99
    },
    {
        "id": 74,
        "name": "Fitness Supplies",
        "category": "Activity",
        "subcategory": "Fitness",
        "createdAt": "2012-07-27T21:44:58.132+05:30",
        "updatedAt": "2012-07-27T21:44:58.132+05:30",
        "price": 158,
        "sale_price": 94.95
    },
    {
        "id": 75,
        "name": "Makeup Equipment",
        "category": "Beauty",
        "subcategory": "Makeup",
        "createdAt": "2016-01-30T11:46:22.140+05:30",
        "updatedAt": "2016-01-30T11:46:22.140+05:30",
        "price": 109.99,
        "sale_price": 87
    },
    {
        "id": 76,
        "name": "Tire Widget",
        "category": "Automotive",
        "subcategory": "Tire",
        "createdAt": "2016-02-14T09:58:39.887+05:30",
        "updatedAt": "2016-02-14T09:58:39.887+05:30",
        "price": 173.95,
        "sale_price": 138
    },
    {
        "id": 77,
        "name": "Swimming Item",
        "category": "Activity",
        "subcategory": "Swimming",
        "createdAt": "2023-11-23T08:57:14.423+05:30",
        "updatedAt": "2023-11-23T08:57:14.423+05:30",
        "price": 162,
        "sale_price": 145.99
    },
    {
        "id": 78,
        "name": "Movie Item",
        "category": "Entertainment",
        "subcategory": "Movie",
        "createdAt": "2011-04-28T04:38:49.861+05:30",
        "updatedAt": "2011-04-28T04:38:49.861+05:30",
        "price": 195.95,
        "sale_price": 117.95
    },
    {
        "id": 79,
        "name": "Engine Whatchamacallit",
        "category": "Automotive",
        "subcategory": "Engine",
        "createdAt": "2012-08-12T23:36:54.963+05:30",
        "updatedAt": "2012-08-12T23:36:54.963+05:30",
        "price": 33.99,
        "sale_price": 26.95
    },
    {
        "id": 80,
        "name": "Storage Gizmo",
        "category": "Home",
        "subcategory": "Storage",
        "createdAt": "2014-03-02T05:07:40.001+05:30",
        "updatedAt": "2014-03-02T05:07:40.001+05:30",
        "price": 38.99,
        "sale_price": 34.95
    },
    {
        "id": 81,
        "name": "Toy Accessory",
        "category": "Entertainment",
        "subcategory": "Toy",
        "createdAt": "2010-06-03T12:42:39.695+05:30",
        "updatedAt": "2010-06-03T12:42:39.695+05:30",
        "price": 11
    },
    {
        "id": 82,
        "name": "Womens Attachment",
        "category": "Clothing",
        "subcategory": "Womens",
        "createdAt": "2011-02-22T23:14:54.029+05:30",
        "updatedAt": "2011-02-22T23:14:54.029+05:30",
        "price": 169,
        "sale_price": 101
    },
    {
        "id": 83,
        "name": "Nutrition Whatchamacallit",
        "category": "Health",
        "subcategory": "Nutrition",
        "createdAt": "2015-01-10T09:13:41.794+05:30",
        "updatedAt": "2015-01-10T09:13:41.794+05:30",
        "price": 151.99,
        "sale_price": 105
    },
    {
        "id": 84,
        "name": "Biking Item",
        "category": "Activity",
        "subcategory": "Biking",
        "createdAt": "2017-01-24T12:12:11.317+05:30",
        "updatedAt": "2017-01-24T12:12:11.317+05:30",
        "price": 78.95,
        "sale_price": 46.99
    },
    {
        "id": 85,
        "name": "Tire Accessory",
        "category": "Automotive",
        "subcategory": "Tire",
        "createdAt": "2019-12-29T05:07:44.754+05:30",
        "updatedAt": "2019-12-29T05:07:44.754+05:30",
        "price": 192.99,
        "sale_price": 172.99
    },
    {
        "id": 86,
        "name": "Camera Supplies",
        "category": "Electronics",
        "subcategory": "Camera",
        "createdAt": "2016-11-29T19:55:12.630+05:30",
        "updatedAt": "2016-11-29T19:55:12.630+05:30",
        "price": 110.95,
        "sale_price": 99.99
    },
    {
        "id": 87,
        "name": "Medicine Paraphernalia",
        "category": "Health",
        "subcategory": "Medicine",
        "createdAt": "2020-04-26T18:50:23.993+05:30",
        "updatedAt": "2020-04-26T18:50:23.993+05:30",
        "price": 171.9
    },
    {
        "id": 88,
        "name": "Book Supplies",
        "category": "Entertainment",
        "subcategory": "Book",
        "createdAt": "2018-10-11T16:37:46.669+05:30",
        "updatedAt": "2018-10-11T16:37:46.669+05:30",
        "price": 164.95,
        "sale_price": 131.95
    },
    {
        "id": 89,
        "name": "Camera Tool",
        "category": "Electronics",
        "subcategory": "Camera",
        "createdAt": "2018-01-18T08:28:59.263+05:30",
        "updatedAt": "2018-01-18T08:28:59.263+05:30",
        "price": 50,
        "sale_price": 30.95
    },
    {
        "id": 90,
        "name": "Kids Attachment",
        "category": "Clothing",
        "subcategory": "Kids",
        "createdAt": "2011-03-04T03:22:19.023+05:30",
        "updatedAt": "2011-03-04T03:22:19.023+05:30",
        "price": 62,
        "sale_price": 43
    },
    {
        "id": 91,
        "name": "Tire Rig",
        "category": "Automotive",
        "subcategory": "Tire",
        "createdAt": "2021-06-16T16:04:59.818+05:30",
        "updatedAt": "2021-06-16T16:04:59.818+05:30",
        "price": 93,
        "sale_price": 83.99
    },
    {
        "id": 92,
        "name": "Furniture Implement",
        "category": "Home",
        "subcategory": "Furniture",
        "createdAt": "2016-01-20T18:13:54.097+05:30",
        "updatedAt": "2016-01-20T18:13:54.097+05:30",
        "price": 30.95,
        "sale_price": 24.99
    },
    {
        "id": 93,
        "name": "Cleaning Gadget",
        "category": "Home",
        "subcategory": "Cleaning",
        "createdAt": "2013-10-05T18:02:06.050+05:30",
        "updatedAt": "2013-10-05T18:02:06.050+05:30",
        "price": 46.99,
        "sale_price": 36.99
    },
    {
        "id": 94,
        "name": "Dog Device",
        "category": "Pets",
        "subcategory": "Dog",
        "createdAt": "2021-10-30T19:12:11.164+05:30",
        "updatedAt": "2021-10-30T19:12:11.164+05:30",
        "price": 43,
        "sale_price": 30.99
    },
    {
        "id": 95,
        "name": "Bedding Rig",
        "category": "Home",
        "subcategory": "Bedding",
        "createdAt": "2021-03-02T23:34:46.611+05:30",
        "updatedAt": "2021-03-02T23:34:46.611+05:30",
        "price": 183,
        "sale_price": 146.99
    },
    {
        "id": 96,
        "name": "Bath Thingamajig",
        "category": "Beauty",
        "subcategory": "Bath",
        "createdAt": "2017-01-24T04:11:46.001+05:30",
        "updatedAt": "2017-01-24T04:11:46.001+05:30",
        "price": 34.95,
        "sale_price": 27
    },
    {
        "id": 97,
        "name": "Camping Mechanism",
        "category": "Activity",
        "subcategory": "Camping",
        "createdAt": "2022-07-22T05:46:44.807+05:30",
        "updatedAt": "2022-07-22T05:46:44.807+05:30",
        "price": 17
    },
    {
        "id": 98,
        "name": "Dog Gizmo",
        "category": "Pets",
        "subcategory": "Dog",
        "createdAt": "2012-08-05T20:59:08.313+05:30",
        "updatedAt": "2012-08-05T20:59:08.313+05:30",
        "price": 162.99,
        "sale_price": 97.99
    },
    {
        "id": 99,
        "name": "Storage Accessory",
        "category": "Home",
        "subcategory": "Storage",
        "createdAt": "2013-06-20T03:21:32.678+05:30",
        "updatedAt": "2013-06-20T03:21:32.678+05:30",
        "price": 157.99,
        "sale_price": 94
    },
    {
        "id": 100,
        "name": "Fitness Attachment",
        "category": "Activity",
        "subcategory": "Fitness",
        "createdAt": "2016-02-11T17:34:36.494+05:30",
        "updatedAt": "2016-02-11T17:34:36.494+05:30",
        "price": 60.99,
        "sale_price": 48.95
    }
]
interface CellType {
    getValue: <T>() => T;
}
export const columns = [
    { accessorKey: 'id', header: 'ID', size: 40 },
    { accessorKey: 'name', header: 'Name', size: 50 },
    { accessorKey: 'category', header: 'Category', size: 60 },
    { accessorKey: 'subcategory', header: 'Subcategory', size: 60 },
    {
        accessorKey: 'createdAt', header: 'Created At',
        Cell: ({ cell }: { cell: CellType }) => format(new Date(cell?.getValue<string>()), 'dd-MMM-yy'), size: 50
    },
    {
        accessorKey: 'updatedAt', header: 'Updated At',
        Cell: ({ cell }: { cell: CellType }) => format(new Date(cell?.getValue<string>()), 'dd-MMM-yy'), size: 50
    },
    { accessorKey: 'price', header: 'Price', size: 50, columnFilterModeOptions: ['between', 'greaterThan', 'lessThan'], },
    { accessorKey: 'sale_price', header: 'Sale Price', size: 50, columnFilterModeOptions: ['between', 'greaterThan', 'lessThan'], },
];
