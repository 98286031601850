import React, { useEffect, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Box, Grid, CssBaseline, Container } from '@mui/material';
import { sampleData, columns } from './utils/data';
import './App.css';
import Navbar from './components/Navbar';
import GroupingComponent from './components/GroupingComponent';
import ViewComponent from './components/ViewComponent';
import SortingSidebar from './components/SortingSidebar';
import FilteringSidebar from './components/FilterSidebar';
import { isWithinInterval, parseISO } from 'date-fns';

const App: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [allColumns, setAllColumns] = useState(columns);
  const [sortOpen, setSortOpen] = useState<boolean>(false);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [catSearch, setCatSearch] = useState<string>('');
  const [subCatSearch, setSubCatSearch] = useState<string>('');
  const [priceFilter, setPriceFilter] = useState<[number, number]>([11, 100]);
  const [state, setState] = useState({
    enableGrouping: false,
    grouping: [],
    globalFilter: '',
  });
  const [nameFilter, setNameFilter] = useState<string>('');
  const [filteredData, setFilteredData] = useState(sampleData);
  const [salePriceFilter, setSalePriceFilter] = useState<[number, number]>([11, 100]);
  const [createRange, setCreateRange] = useState<[string | null, string | null]>(['1900-01-01', new Date().toISOString().split('T')[0]]);
  const [updateRange, setUpdateRange] = useState<[string | null, string | null]>(['1900-01-01', new Date().toISOString().split('T')[0]]);
  useEffect(() => {
    if (nameFilter === '') {
      setFilteredData(sampleData);
      return;
    }
    setFilteredData(sampleData.filter(item =>
      item.name.toLowerCase().includes(nameFilter.toLowerCase())
    ));

  }, [nameFilter]);
  useEffect(() => {
    if (catSearch === '') {
      setFilteredData(sampleData);
      return;
    }

    const searchTerms = catSearch.split(" ").map(term => term.toLowerCase());
    const filtered = sampleData.filter(item =>
      searchTerms.some(term => item.category.toLowerCase().includes(term))
    );

    setFilteredData(filtered);
  }, [catSearch]);
  useEffect(() => {
    if (subCatSearch === '') {
      setFilteredData(sampleData);
      return;
    }

    const searchTerms = subCatSearch.split(" ").map(term => term.toLowerCase());
    const filtered = sampleData.filter(item =>
      searchTerms.some(term => item.subcategory.toLowerCase().includes(term))
    );

    setFilteredData(filtered);
  }, [subCatSearch]);
  useEffect(() => {
    if (priceFilter[0] === 11 && priceFilter[1] === 100) {
      setFilteredData(sampleData);
      return;
    }

    const filtered = sampleData.filter(item =>
      item.price >= priceFilter[0] && item.price <= priceFilter[1]
    );

    setFilteredData(filtered);
  }, [priceFilter]);
  useEffect(() => {
    if (salePriceFilter[0] === 11 && salePriceFilter[1] === 100) {
      setFilteredData(sampleData);
      return;
    }

    const filtered = sampleData.filter(item =>
      item.price >= salePriceFilter[0] && item.price <= salePriceFilter[1]
    );

    setFilteredData(filtered);
  }, [salePriceFilter]);
  useEffect(() => {
    const filtered = sampleData.filter(item => {
      return createRange[0] !== null && createRange[1] !== null &&
        isWithinInterval(parseISO(item.createdAt), {
          start: parseISO(createRange[0]),
          end: parseISO(createRange[1])
        });
    });
    setFilteredData(filtered);
  }, [createRange]);

  useEffect(() => {
    const filtered = sampleData.filter(item => {
      return updateRange[0] !== null && updateRange[1] !== null &&
        isWithinInterval(parseISO(item.updatedAt), {
          start: parseISO(updateRange[0]),
          end: parseISO(updateRange[1])
        });
    });
    setFilteredData(filtered);
  }, [updateRange]);
  return (
    <div style={{ padding: '20px' }}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box width={"100%"} mb={2}>
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }} className='navbar'>

                <Navbar setIsDrawerOpen={setIsDrawerOpen} setState={setState} setViewOpen={setViewOpen} setSortOpen={setSortOpen} setFilterOpen={setFilterOpen} />

              </div>
            </Box>
            <MaterialReactTable
              columns={allColumns}
              data={filteredData}
              enableColumnOrdering={false}
              enableColumnFilters={false}
              enableFullScreenToggle={false}
              enablePagination
              enableGrouping={state?.enableGrouping ?? false}
              enableDensityToggle={false}
              enableHiding={false}
              pageCount={10}
              enableColumnDragging={false}
              muiTableContainerProps={{
                style: { height: '78vh', overflow: 'auto' },
              }}
              displayColumnDefOptions={
                {
                  "mrt-row-expand": {
                    size: 10
                  }
                }
              }
              enableGlobalFilter={true}
              initialState={{
                density: 'comfortable',
              }}
              muiTableBodyProps={{
                style: { textAlign: 'center' },
              }}
              enableTopToolbar={false}
              state={{
                ...state, showAlertBanner: false, showColumnFilters: false, showSkeletons: false
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <GroupingComponent isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} setState={setState} />
      <ViewComponent setState={setState} isDrawerOpen={viewOpen} setIsDrawerOpen={setViewOpen} setAllColumns={setAllColumns} />
      <SortingSidebar setState={setState} isDrawerOpen={sortOpen} setIsDrawerOpen={setSortOpen} />
      <FilteringSidebar setCatSearch={setCatSearch} setSubCatSearch={setSubCatSearch} setState={setState} isDrawerOpen={filterOpen} setIsDrawerOpen={setFilterOpen} setNameFilter={setNameFilter} setPriceFilter={setPriceFilter} setSalePriceFilter={setSalePriceFilter} setCreateRange={setCreateRange} setUpdateRange={setUpdateRange} />
    </div>
  );
};

export default App;