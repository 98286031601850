import React, { useState } from 'react';
import {
    Drawer,
    Button,
    List,
    ListItem,
    TextField,
    IconButton,
    FormControl,
    Box,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CategoryFilter from './Categoryfilter';
import SubcategoryFilter from './Subcategoryfilter';
import DateRange from './DateRange';
import Slider from '@mui/material/Slider';
interface FilterSidebarProps {
    setState: (state: any) => void;
    setIsDrawerOpen: (isOpen: boolean) => void;
    isDrawerOpen: boolean;
    setNameFilter: (name: string) => void;
    setCatSearch: (catSearch: string) => void;
    setSubCatSearch: (subCatSearch: string) => void;
    setPriceFilter: (price: [number, number]) => void;
    setSalePriceFilter: (price: [number, number]) => void;
    setCreateRange: (range: [string | null, string | null]) => void;
    setUpdateRange: (range: [string | null, string | null]) => void;
}
const FilteringSidebar: React.FC<FilterSidebarProps> = ({ setIsDrawerOpen, isDrawerOpen, setNameFilter, setCatSearch, setSubCatSearch, setPriceFilter, setSalePriceFilter, setCreateRange, setUpdateRange }) => {
    const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
    const [subcategoryFilter, setSubcategoryFilter] = useState<string[]>([]);
    const [createdAtFilter, setCreatedAtFilter] = useState<[string | null, string | null]>([null, null]);
    const [updatedAtFilter, setUpdatedAtFilter] = useState<[string | null, string | null]>([null, null]);
    const [catMax, setCatMax] = useState<number>(100);
    const [catMin, setCatMin] = useState<number>(11);
    const [subCatMax, setSubCatMax] = useState<number>(100);
    const [subCatMin, setSubCatMin] = useState<number>(11);
    const clearAllFilters = () => {
        setNameFilter('');
        setCategoryFilter([]);
        setSubcategoryFilter([]);
        setCreatedAtFilter([null, null]);
        setUpdatedAtFilter([null, null]);
        setPriceFilter([11, 100]);
        setSalePriceFilter([11, 100]);
    };

    return (
        <Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <Box p={2} width="300px">
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Sorting Options</Typography>
                    <IconButton onClick={() => setIsDrawerOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <List>
                    <ListItem>
                        <TextField
                            label="Name"
                            onChange={(e) => setNameFilter(e.target.value)}
                            fullWidth
                        />
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth>
                            <CategoryFilter setCatSearch={setCatSearch} categoryFilter={categoryFilter} setCategoryFilter={setCategoryFilter} />
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth>
                            <SubcategoryFilter setSubCatSearch={setSubCatSearch} subcategoryFilter={subcategoryFilter} setSubcategoryFilter={setSubcategoryFilter} />
                        </FormControl>
                    </ListItem>
                    <ListItem style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>

                        <Typography variant="h6">Created At</Typography>
                        <DateRange setRange={setCreateRange} dates={createdAtFilter} setDates={setCreatedAtFilter} />
                    </ListItem>
                    <ListItem style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>

                        <Typography variant="h6">Updated At</Typography>
                        <DateRange setRange={setUpdateRange} dates={updatedAtFilter} setDates={setUpdatedAtFilter} />
                    </ListItem>
                    <ListItem style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>

                        <Typography variant="h6">Price</Typography>
                        <Slider
                            getAriaLabel={() => 'Price'}
                            value={[catMin, catMax]}
                            onChange={(event: Event, newValue: number | number[]) => {
                                const [min, max] = newValue as number[];
                                setCatMax(max);
                                setCatMin(min);
                                setPriceFilter([min, max]);
                            }}
                            min={11}
                            max={100}
                            valueLabelDisplay="auto"
                        />
                    </ListItem>
                    <ListItem style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>

                        <Typography variant="h6">Sale Price</Typography>
                        <Slider
                            getAriaLabel={() => 'Sale Price'}
                            value={[subCatMin, subCatMax]}
                            onChange={(event: Event, newValue: number | number[]) => {
                                const [min, max] = newValue as number[];
                                setSubCatMax(max);
                                setSubCatMin(min);
                                setSalePriceFilter([min, max]);
                            }}
                            min={11}
                            max={100}
                            valueLabelDisplay="auto"
                        />
                    </ListItem>
                    <ListItem>
                        <Button onClick={clearAllFilters} fullWidth style={{ border: "1px solid blue", color: "black" }}>
                            Clear Filters
                        </Button>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    );
};

export default FilteringSidebar;
