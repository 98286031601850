
import React, { useEffect, useState } from 'react';
import {
    Drawer,
    FormControlLabel,
    Switch,
    Button,
    List,
    ListItem,
    Box,
    Typography,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { columns as FirstColumns } from '../utils/data';

interface Column {
    id: string;
    label: string;
    visible: boolean;
}

interface ViewComponentProps {
    isDrawerOpen: boolean;
    setIsDrawerOpen: (isOpen: boolean) => void;
    setAllColumns: (columns: any) => void;
    setState: (state: any) => void;
}

const columnsData: Column[] = [
    { id: 'id', label: 'ID', visible: true },
    { id: 'name', label: 'Name', visible: true },
    { id: 'category', label: 'Category', visible: true },
    { id: 'subcategory', label: 'Subcategory', visible: true },
    { id: 'createdAt', label: 'Created At', visible: true },
    { id: 'updatedAt', label: 'Updated At', visible: true },
    { id: 'price', label: 'Price', visible: true },
    { id: 'sale_price', label: 'Sale Price', visible: true }
];

const ViewComponent: React.FC<ViewComponentProps> = ({ setState, isDrawerOpen, setIsDrawerOpen, setAllColumns }) => {
    const [columns, setColumns] = useState<Column[]>(columnsData);
    const [selectedColumn, setSelectedColumn] = useState<{
        [key: string]: boolean;
    }>({
        id: true,
        name: true,
        category: true,
        subcategory: true,
        createdAt: true,
        updatedAt: true,
        price: true,
        sale_price: true
    });
    const toggleColumnVisibility = (id: string) => {
        setColumns(prevColumns =>
            prevColumns.map(column =>
                column.id === id
                    ? { ...column, visible: !column.visible }
                    : column
            )
        );
        setSelectedColumn((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    useEffect(() => {
        const updatedColumns = FirstColumns.map(col => ({
            ...col,
            visible: columns.find(c => c.id === col.accessorKey)?.visible ?? true
        }));
        setAllColumns(updatedColumns);
    }, [columns, setAllColumns]);

    return (
        <Drawer anchor="right" open={isDrawerOpen} onClose={() => { setIsDrawerOpen(false) }}>
            <Box p={2} width="300px">
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Show/Hide Columns</Typography>
                    <IconButton onClick={() => setIsDrawerOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <List>
                    {columns.map(column => (
                        <ListItem key={column.id} style={{ display: "flex", justifyContent: "space-between", borderRadius: "2px", alignItems: "center", margin: "1px", border: "1px solid #ebeff2" }}>
                            <Typography>{column.label}</Typography>
                            <FormControlLabel

                                control={
                                    <Switch
                                        checked={column.visible}
                                        onChange={() => toggleColumnVisibility(column.id)}
                                    />
                                }
                                label=''
                            />

                        </ListItem>
                    ))}
                </List>
                <Button fullWidth variant="contained" color="primary" onClick={() => {
                    setState((prevState: any) => ({
                        ...prevState,
                        columnVisibility: selectedColumn
                    }));
                }} sx={{ mt: 2 }}>
                    Apply
                </Button>
                <Button fullWidth style={{ color: "black", border: "1px solid blue" }} onClick={() => {
                    setSelectedColumn({
                        id: true,
                        name: true,
                        category: true,
                        subcategory: true,
                        createdAt: true,
                        updatedAt: true,
                        price: true,
                        sale_price: true
                    });
                    setColumns(columnsData);
                    setAllColumns(FirstColumns);
                    setState((prevState: any) => ({
                        ...prevState,
                        columnVisibility: {
                            id: true,
                            name: true,
                            category: true,
                            subcategory: true,
                            createdAt: true,
                            updatedAt: true,
                            price: true,
                            sale_price: true
                        }
                    }));
                }} sx={{ mt: 2 }}>
                    Show all columns
                </Button>
            </Box>
        </Drawer>
    );
};

export default ViewComponent;