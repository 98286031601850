import React, { useEffect, useState } from 'react';
import {
    Drawer,
    Button,
    List,
    ListItem,
    IconButton,
    Box,
    Typography
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CloseIcon from '@mui/icons-material/Close';
import SwapVertIcon from '@mui/icons-material/SwapVert';

interface Column {
    id: string;
    label: string;
    sortDirection: 'asc' | 'desc' | null;
}

const columnsData: Column[] = [
    { id: 'id', label: 'ID', sortDirection: null },
    { id: 'name', label: 'Name', sortDirection: null },
    { id: 'category', label: 'Category', sortDirection: null },
    { id: 'subcategory', label: 'Subcategory', sortDirection: null },
    { id: 'createdAt', label: 'Created At', sortDirection: null },
    { id: 'updatedAt', label: 'Updated At', sortDirection: null },
    { id: 'price', label: 'Price', sortDirection: null },
    { id: 'sale_price', label: 'Sale Price', sortDirection: null }
];
interface SortingSidebarProps {
    setState: (state: any) => void;
    setIsDrawerOpen: (isOpen: boolean) => void;
    isDrawerOpen: boolean;
}
const SortingSidebar: React.FC<SortingSidebarProps> = ({ setState, setIsDrawerOpen, isDrawerOpen }) => {
    const [columns, setColumns] = useState<Column[]>(columnsData);

    const toggleSortDirection = (id: string) => {
        setColumns(prevColumns =>
            prevColumns.map(column =>
                column.id === id
                    ? {
                        ...column,
                        sortDirection: column.sortDirection === null ? 'asc' : column.sortDirection === 'asc' ? "desc" : column.sortDirection === 'desc' ? null : null
                    }
                    : column
            )
        );
    };

    const clearSort = () => {
        setColumns(prevColumns =>
            prevColumns.map(column => ({ ...column, sortDirection: null }))
        );
    };
    useEffect(() => {
        const sortedColumns = columns.filter(column => column.sortDirection !== null);
        setState((prevState: any) => ({
            ...prevState,
            sorting: sortedColumns.map(column => ({ id: column.id, desc: column.sortDirection === 'desc' }))
        }));
    }, [columns, setState]);
    return (
        <Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <Box p={2} width="300px">
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Sorting Options</Typography>
                    <IconButton onClick={() => setIsDrawerOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <List>
                    {columns.map(column => (
                        <ListItem key={column.id} onClick={() => toggleSortDirection(column.id)} style={{ display: "flex", gap: "5px", width: "100%", justifyContent: "flex-start", borderRadius: "2px", alignItems: "center", margin: "1px", marginTop: "5px", marginBottom: "5px", border: "1px solid #ebeff2" }}>
                            <Typography>
                                {column.label}
                            </Typography>
                            {column.sortDirection ? (<>
                                {column.sortDirection === 'asc' ? <ArrowUpwardIcon style={{ color: "#92999e" }} /> : <ArrowDownwardIcon style={{ color: "#92999e" }} />}
                            </>) : <SwapVertIcon style={{ color: "#92999e" }} />}
                        </ListItem>
                    ))}
                </List>
                <Button onClick={clearSort} fullWidth style={{ color: "black", border: "1px solid blue" }}>Clear Sort</Button>
            </Box>

        </Drawer>
    );
};

export default SortingSidebar;
