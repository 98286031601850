import React, { useState } from 'react';
import { Box, Drawer, IconButton, Typography, FormControl, InputLabel, Select, MenuItem, Button, SelectChangeEvent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface GroupingComponentProps {
    isDrawerOpen: boolean;
    setIsDrawerOpen: (isOpen: boolean) => void;
    setState: (state: any) => void;
}

const GroupingComponent: React.FC<GroupingComponentProps> = ({ isDrawerOpen, setIsDrawerOpen, setState }) => {
    const [selectedColumn, setSelectedColumn] = useState<string>('');

    const handleColumnChange = (event: SelectChangeEvent<string>) => {
        setSelectedColumn(event.target.value as string);
    };

    const clearGrouping = () => {
        setSelectedColumn('');
        setState((prevState: any) => ({
            ...prevState,
            grouping: [],
            enableGrouping: false,
        }));
    };

    const applyGrouping = () => {
        setState((prevState: any) => ({
            ...prevState,
            grouping: [selectedColumn],
            enableGrouping: true,
        }));
    };

    return (
        <Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <Box p={2} width="300px">
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Create Groups</Typography>
                    <IconButton onClick={() => setIsDrawerOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Select a column</InputLabel>
                    <Select value={selectedColumn} onChange={handleColumnChange} label="Select a column"
                        sx={{
                            '& .MuiInputBase-root': {
                                borderRadius: '4px',
                                borderColor: 'rgba(0, 0, 0, 0.23)',
                            },
                            '& .MuiInputLabel-root': {
                                top: '-6px',
                            },
                        }}>
                        <MenuItem value="category">Category</MenuItem>
                        <MenuItem value="subcategory">Subcategory</MenuItem>
                    </Select>
                </FormControl>
                <Button fullWidth variant="contained" color="primary" onClick={clearGrouping} sx={{ mt: 2 }}>
                    Clear Grouping
                </Button>
                <Button fullWidth variant="contained" color="primary" onClick={applyGrouping} sx={{ mt: 2 }}>
                    Apply Grouping
                </Button>
            </Box>
        </Drawer>
    );
};

export default GroupingComponent;

