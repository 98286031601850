import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { sampleData } from '../utils/data';
interface CategoryFilterProps {
    categoryFilter: string[];
    setCategoryFilter: (categories: string[]) => void;
    setCatSearch: (catSearch: string) => void;
}


const CategoryFilter: React.FC<CategoryFilterProps> = ({ setCatSearch, categoryFilter, setCategoryFilter }) => {
    const [catOptions, setCatOptions] = useState<string[]>([]);
    useEffect(() => {
        const categoryCount: { [key: string]: number } = {};
        sampleData.forEach((item) => {
            categoryCount[item.category] = (categoryCount[item.category] || 0) + 1;
        });

        const formattedCategories = Object.entries(categoryCount).map(
            ([category, count]) => `${category} (${count})`
        );

        setCatOptions(formattedCategories);
    }, []);
    useEffect(() => {
        console.log(categoryFilter.map((item) => item.split(" ")[0]).join(" "));
        setCatSearch(categoryFilter.map((item) => item.split(" ")[0]).join(" "));
    }, [categoryFilter, setCatSearch]);
    return (
        <Autocomplete
            multiple
            options={catOptions}
            value={categoryFilter}
            onChange={(event, newValue) => setCategoryFilter(newValue)}
            renderInput={(params) => <TextField {...params} label="Category" />}
        />
    );
};

export default CategoryFilter;
