import React, { useState } from 'react';
import { IconButton, InputBase } from '@mui/material';
import { Visibility, FilterList, Layers } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import './Navbar.css';
interface NavbarProps {
    setIsDrawerOpen: (isOpen: boolean) => void;
    setState: (state: any) => void;
    setViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSortOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const Navbar = ({ setIsDrawerOpen, setState, setViewOpen, setSortOpen, setFilterOpen }: NavbarProps) => {
    const [isVisible, setIsVisible] = useState<boolean>(true);
    const [isSorted, setIsSorted] = useState<boolean>(false);
    const [isFiltered, setIsFiltered] = useState<boolean>(false);
    const [isStacked, setIsStacked] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        setState((prevState: any) => ({ ...prevState, globalFilter: e.target.value }));
    };

    return (
        <div className="search-bar">
            <div style={{
                border: '1px solid #ccc',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>

                <InputBase
                    onChange={handleSearchChange}
                    placeholder="Search"
                    sx={{ ml: 1, flex: 1 }}
                    value={search}
                />
                <CloseIcon onClick={() => {
                    setState((prev: any) => ({ ...prev, globalFilter: "" }))
                    setSearch("")
                }} style={{
                    cursor: 'pointer',
                    marginRight: '10px',
                    color: 'gray',
                }} />
            </div>
            <div>
                <IconButton onClick={() => {
                    setIsVisible(!isVisible)
                    setViewOpen((prev: boolean) => !prev)
                }} sx={{ p: '10px' }}>
                    <Visibility />
                </IconButton>
                <IconButton onClick={() => {
                    setIsSorted(!isSorted)
                    setSortOpen((prev: boolean) => !prev)
                }} sx={{ p: '10px' }}>
                    <SwapVertIcon />
                </IconButton>
                <IconButton onClick={() => {
                    setIsFiltered(!isFiltered)
                    setFilterOpen((prev: boolean) => !prev)
                }} sx={{ p: '10px' }}>
                    <FilterList />
                </IconButton>
                <IconButton onClick={() => {
                    setIsStacked(!isStacked)
                    setIsDrawerOpen(true)
                }} sx={{ p: '10px' }}>
                    <Layers />
                </IconButton>
            </div>
        </div>
    );
};

export default Navbar;
