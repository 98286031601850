import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { sampleData } from '../utils/data';
interface CategoryFilterProps {
    subcategoryFilter: string[];
    setSubcategoryFilter: (categories: string[]) => void;
    setSubCatSearch: (name: string) => void;
}


const CategoryFilter: React.FC<CategoryFilterProps> = ({ setSubCatSearch, subcategoryFilter, setSubcategoryFilter }) => {
    const [catOptions, setCatOptions] = useState<string[]>([]);
    useEffect(() => {
        const categoryCount: { [key: string]: number } = {};
        sampleData.forEach((item) => {
            categoryCount[item.subcategory] = (categoryCount[item.subcategory] || 0) + 1;
        });

        const formattedCategories = Object.entries(categoryCount).map(
            ([category, count]) => `${category} (${count})`
        );

        setCatOptions(formattedCategories);
    }, []);
    useEffect(() => {
        setSubCatSearch(subcategoryFilter.join(" "));
    }, [subcategoryFilter, setSubCatSearch]);
    return (
        <Autocomplete
            multiple
            options={catOptions}
            value={subcategoryFilter}
            onChange={(event, newValue) => setSubcategoryFilter(newValue)}
            renderInput={(params) => <TextField {...params} label="Subcategory" />}
        />
    );
};

export default CategoryFilter;
